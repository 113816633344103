@import "bootstrap";

@import "bootstrap-select";

@import "toastr";

@import "bootstrap-datepicker";

@import "bootstrap-datepicker.standalone";

@import "variables";

@import "components";

.btn-mobile {
  width: 100%;
  display:block;
}

@include media-breakpoint-up(sm) {
  .btn-mobile { display: inline-block; width: auto; }
}

ul.books {
  list-style-type: none;
}

.vh-100 {
  height: 100vh;
}

.aligner-center {
  &-lg {
    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.table-sm td {
  vertical-align: middle;
}

.invalid-feedback.invalid-custom {
  display: block;
}

.form-widget-top-5px {
  margin-top: 5px;
}

.table-responsive td span.badge {
  font-weight: 400 !important;
  font-size: 13px;
}

input[type="color"] {
  height: 38px !important;
}

text.highcharts-credits {
  display: none;
}

// color picker block with preview
.color-picker-block {
  position: relative;
}
.color-picker-preview {
  position: relative;
  display: inline-table;
  .color-picker-preview-img {
    min-height: 250px;
    min-width: 250px;
    border-radius: 10px;
  }
  .color-picker-preview-title {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px 10px;
    span {
      position: relative;
      z-index: 1;
      color: #fff;
      font-size: 16px;
      font-weight: 700;

    }
    .color-picker-preview-background {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 0;
      height: 100%;
      width: 100%;
      background-color: #D1CFE4;
      opacity: 0.7;
      border-radius: 0 0 10px 10px;
    }
  }
}
// end color picker block with preview